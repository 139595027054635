export class BcCookie {

    constructor() {

    }

    static create(name, value, expirationInDays) {
        const d = new Date();
        d.setTime(d.getTime() + (expirationInDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();

        document.cookie = name + "=" + value + ";" + expires + ";path=/";

        console.log('Cookie ' + name + ' created successfully')
    }

    static delete(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        console.log('Cookie ' + name + ' deleted successfully')
    }

    static get(name) {
        name = name + "=";
        let cookieArray = document.cookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let c = cookieArray[i];

            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }


}

