import 'bootstrap';
import { Tooltip } from 'bootstrap'

import './components/chart.js';
import './components/cookies';
import './components/bitcoin.js';
import './components/validation.js';
import './components/affiliate.js';
import './components/buy-sell-form';
import './components/btcex';
import './components/aml';
import './components/sendDepositSms';
import './admin.js';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import 'material-icons/iconfont/material-icons.css';

window.addEventListener('load', function () {
    const toggleMenu = document.getElementById('mobileMenuButton');
    const menu = document.getElementById('mobileMenu');
    const backdropElement = document.createElement('div');
    backdropElement.id = 'backdropNavbar';


    if (toggleMenu) {
        toggleMenu.addEventListener('click', function () {
            menu.classList.toggle('show');
            toggleMenu.classList.toggle('active');

            if (menu.classList.contains('show')) {
                document.body.appendChild(backdropElement);
            }
            else {
                document.body.removeChild(backdropElement);
            }
        });

        // if click outside menu, close it
        document.addEventListener('click', function (event) {
            if (!menu.contains(event.target) && !toggleMenu.contains(event.target)) {
                menu.classList.remove('show');
                toggleMenu.classList.remove('active');

                if (menu.classList.contains('show')) {
                    document.body.appendChild(backdropElement);
                } else {
                    document.body.removeChild(backdropElement);
                }
            }
        });
    }

    const phoneNumberInputs = document.querySelectorAll(".phone-number-input");

    phoneNumberInputs.forEach(phoneNumberInput => {
        const iti = intlTelInput(phoneNumberInput, {
            separateDialCode: true,
            initialCountry: 'cz',
            preferredCountries: ['cz', 'sk', 'hu', 'fr', 'de', 'en'],
        });

        phoneNumberInput.addEventListener("countrychange", function (e) {
            const dialCode = phoneNumberInput.parentElement.parentElement.parentElement.querySelector('[iti-dial-code]');
            console.log(dialCode);
            if (dialCode) {
                dialCode.value = iti.getSelectedCountryData().dialCode;
            }
        });
    });


    const autoSubmitForms = document.querySelectorAll('.auto-submit-form');

    autoSubmitForms.forEach(autoSubmitForm => {
        autoSubmitForm.addEventListener('change', function () {
            autoSubmitForm.submit();
        });
    });
});

window.addEventListener('DOMContentLoaded', () => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    });

    const myCollapsible = document.getElementById('navbarSupportedContent')

    if (myCollapsible) {
        myCollapsible.addEventListener('show.bs.collapse', function () {
            document.querySelector('#backdropNavbar').style.top = '320px';
            document.querySelector('#backdropNavbar').style.zIndex = 99;
            document.querySelector('#backdropNavbar').classList.toggle('show');
            document.getElementById('mainHeader').classList.add('menu-opened');
            document.body.style.paddingTop = "60px";
        })

        myCollapsible.addEventListener('hide.bs.collapse', function () {
            document.querySelector('#backdropNavbar').classList.toggle('show');
            document.getElementById('mainHeader').classList.remove('menu-opened');

            document.body.style.paddingTop = "0px";
        })
    }
});

function countDown() {
    var now = new Date().getTime();
    var countDownDate = new Date(document.getElementById('countDownStatus').getAttribute('data-time')).getTime();
    var distance = countDownDate - now;
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    document.getElementById("countDownStatus").innerHTML = "(" + minutes + ":" + seconds + ")";

    if (distance < 0) {
        document.getElementById("countDownStatus").innerHTML = "(Kurz vypršel)";
    }
}

window.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('countDownStatus')) {
        setInterval(countDown, 1000);
    }
});

window.addEventListener('DOMContentLoaded', () => {
    const resolutionInput = document.querySelectorAll('[name="resolution"]')
    const btc = document.getElementById('btcInput');

    if (btc) {
        resolutionInput.forEach(el => {
            el.addEventListener('change', function () {
                if (el.value == 'return') {
                    btc.classList.remove('d-none');
                } else {
                    btc.classList.add('d-none');
                }
            });
        });
    }

    const paymentToInput = document.querySelectorAll('[name="payment_to"]')
    const depositBankAccount = document.getElementById('depositBankAccount');
    const depositBtcAccount = document.getElementById('depositBtcAccount');

    if (depositBankAccount && depositBtcAccount) {
        paymentToInput.forEach(el => {
            el.addEventListener('change', function () {
                if (el.value == 'bank_account') {
                    depositBankAccount.classList.remove('d-none');
                    depositBtcAccount.classList.add('d-none');
                    depositBankAccount.querySelector('input').required = true;
                    depositBtcAccount.querySelector('input').required = false;
                } else {
                    depositBankAccount.classList.add('d-none');
                    depositBtcAccount.classList.remove('d-none');
                    depositBtcAccount.querySelector('input').required = true;
                    depositBankAccount.querySelector('input').required = false;
                }
            });
        });
    }
});

window.addEventListener('DOMContentLoaded', () => {
    const main = document.querySelector('main');
    const footerHeight = document.querySelector('.footer');
    const headerHeight = document.querySelector('#mainHeader');

    if (main && footerHeight && headerHeight) {
        main.style.minHeight = `calc(100vh - ${footerHeight.clientHeight}px - ${headerHeight.clientHeight}px - 80px)`;
    }
    if (window.location.hash) {
        var faqCollapse = document.getElementById(window.location.hash.substring(1))
        var bsCollapse = new bootstrap.Collapse(faqCollapse.querySelector('.accordion-collapse')).show()
    }


    const identityForm = document.getElementById('verificationIdentityForm');

    if (identityForm) {
        const resolution = document.querySelectorAll('[name="resolution"]');
        resolution.forEach(el => {
            el.addEventListener('change', function () {
                if (el.value === 'RETURN') {
                    document.getElementById('verificationIdentityFormAccept').classList.add('d-none');
                    document.getElementById('verificationIdentityFormReturn').classList.remove('d-none');
                    const inputsAccept = document.querySelectorAll('#verificationIdentityFormAccept input');
                    const inputsReturn = document.querySelectorAll('#verificationIdentityFormReturn input');

                    inputsAccept.forEach(input => {
                        input.required = false;
                    });

                    inputsReturn.forEach(input => {
                        input.required = true;
                    });
                } else {
                    document.getElementById('verificationIdentityFormAccept').classList.remove('d-none');
                    document.getElementById('verificationIdentityFormReturn').classList.add('d-none');
                    const inputsAccept = document.querySelectorAll('#verificationIdentityFormAccept input');
                    const inputsReturn = document.querySelectorAll('#verificationIdentityFormReturn input');

                    inputsAccept.forEach(input => {
                        input.required = true;
                    });

                    inputsReturn.forEach(input => {
                        input.required = false;
                    });
                }
            });
        });
    }

    // Deposit
    const walletForm = document.getElementById('walletForm');

    if (walletForm) {
        const resolution = document.querySelectorAll('[name="resolution"]');
        const continueBtn = document.getElementById('continueButton');
        const formBtcWallet = document.getElementById('form-btc-wallet');
        const formBankAccount = document.getElementById('f-bank-account');
        resolution.forEach(el => {
            el.addEventListener('change', function () {
                formBtcWallet.value = '';
                formBankAccount.value = '';
                continueBtn.disabled = true;

                if (el.value === 'SELL') {
                    document.getElementById('depositBtcAccount').classList.add('d-none');
                    document.getElementById('depositBankAccount').classList.remove('d-none');
                    const inputsBtc = document.querySelectorAll('#depositBtcAccount input');
                    const inputsBank = document.querySelectorAll('#depositBankAccount input');

                    inputsBtc.forEach(input => {
                        input.required = false;
                    });

                    inputsBank.forEach(input => {
                        input.required = true;
                    });

                } else {
                    document.getElementById('depositBtcAccount').classList.remove('d-none');
                    document.getElementById('depositBankAccount').classList.add('d-none');
                    const inputsBtc = document.querySelectorAll('#depositBtcAccount input');
                    const inputsBank = document.querySelectorAll('#depositBankAccount input');

                    inputsBtc.forEach(input => {
                        input.required = true;
                    });

                    inputsBank.forEach(input => {
                        input.required = false;
                    });
                }
            });
        });


    }

});
