document.addEventListener('DOMContentLoaded', function () {
    const selectedInputs = document.querySelectorAll('.input-count-on-select');
    const selectedInputsCount = document.querySelector('#selectedInputsCount');

    selectedInputs.forEach(function (input) {
        input.addEventListener('change', function () {
            let count = 0;
            selectedInputs.forEach(function (input) {
                if (input.checked) {
                    count++;
                }
            });
            selectedInputsCount.innerText = count;

            if (count > 0) {
                document.querySelector('#bulkActionButton').removeAttribute('disabled');
            } else {
                document.querySelector('#bulkActionButton').setAttribute('disabled', 'disabled');
            }
        });
    });
});