import Cookies from 'js-cookie'
import axios from 'axios'

const daysToExpire = 400;

// handle affiliate registration
document.addEventListener('DOMContentLoaded', function () {
    const affiliateCodeInput = document.getElementById('affiliateCodeInput');
    const affiliateCodeLinkPreview = document.getElementById('affiliateCodeLinkPreview');

    if (affiliateCodeInput) {
        if (affiliateCodeInput.value) {
            affiliateCodeLinkPreview.innerHTML = window.location.origin + '/' + affiliateCodeInput.value;
        } else {
            affiliateCodeLinkPreview.innerHTML = window.location.origin + '/' + 'vas-identifikator';
        }

        affiliateCodeInput.addEventListener('keyup', function () {
            affiliateCodeLinkPreview.innerHTML = window.location.origin + '/' + affiliateCodeInput.value;

            if (affiliateCodeInput.value) {
                affiliateCodeLinkPreview.classList.remove('d-none');
            } else {
                affiliateCodeLinkPreview.classList.add('d-none');
            }

        });
    }
});

// handle affiliate cookie
document.addEventListener('DOMContentLoaded', function () {
    const affiliate = new URL(location.href).searchParams.get('a');

    if (affiliate) {
        axios
            .post(`/api/affiliate/${affiliate}`)
            .then(response => {
                Cookies.set('affiliate', response.data.code, {
                    expires: daysToExpire,
                });
            })
            .catch(error => {
                if (!error.response || error.response.status !== 404) return console.log('Something went wrong')

                console.log('Affiliate neexistuje')
            });
    }
});