import Quill from 'quill';

window.addEventListener('DOMContentLoaded', () => {
    const quill = document.getElementsByClassName('quill');

    const toolbarOptionsProfile = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }]
    ];

    if (quill.length) {

        const content = document.querySelector('.editorjs');

        let editor = new Quill('.editorjs', {
            modules: {
                toolbar: toolbarOptionsProfile
            },
            theme: 'snow'
        });

        if (content) {
            document.getElementById('formPage').onsubmit = function () {
                let content = document.getElementById('pageContent');
                content.value = editor.root.innerHTML;
            };
        }
    }
});