window.addEventListener('DOMContentLoaded', () => {
    const incomeSourceSelect = document.getElementById('income_source');

    if (incomeSourceSelect) {
        incomeSourceSelect.addEventListener('change', () => {
            const incomeSourceOther = document.querySelectorAll('.only-income-source-other');

            if (incomeSourceSelect.value === 'OTHER') {
                incomeSourceOther.forEach(el => {
                    el.classList.remove('d-none');
                });
            } else { 
                incomeSourceOther.forEach(el => {
                    el.classList.add('d-none');
                });
            }
        });
    }
});