import { setError } from "./validation";
import 'bootstrap';
window.bootstrap = require("bootstrap");

window.addEventListener('DOMContentLoaded', () => {
    const minimalBitcoinPurchase = 0.003;
    const maxBitcoinPurchase = 2;
    const btcRatio = 100000000;
    const czkRatio = 100;

    function onChangeAmount(input, currency) {
        let value = input.value.replace(/ /g, '');
        value = parseFloat(input.value.replace(",", "."));

        if (currency === 'CZK') {
            value = value * czkRatio;
        } else if (currency === 'BTC') {
            value = value * btcRatio;
        }

        recalculateExchange(value, currency, true);
    }

    async function recalculateExchange(amount, currency, resetErrors = true) {
        const type = document.querySelector('[name="form_type"]:checked').value.toUpperCase();
        const url = `https://api.btcex.cz/price?type=${type}&amount=${amount}&currency=${currency}&public_key=5r95wf1`;

        if (type === 'BUY' || type === 'SELL') {
            await fetch(url)
                .then(data => {
                    return data.json();
                })
                .then(data => {
                    if (resetErrors) {
                        if (type === 'BUY') {
                            setError(document.getElementById('buy-form-price-error'), '');
                        } else {
                            setError(document.getElementById('form-price-error'), '');
                        }
                    }

                    if (data.status === 400) {
                        recalculateExchange(minimalBitcoinPurchase * btcRatio, "BTC", false);
                        if (type === 'BUY') {
                            setError(document.getElementById('buy-form-price-error'), data.detail);
                        } else {
                            setError(document.getElementById('form-price-error'), data.detail);
                        }
                        throw new Error(data.details);
                    }

                    if (data.btc.amount_decimal > maxBitcoinPurchase) {
                        recalculateExchange(maxBitcoinPurchase * btcRatio, "BTC", false);

                        setError(document.getElementById('form-price-error'), `Vámi zadaná hodnota objednávky je vyšší, než povolené maximum (2 BTC).`);
                        throw new Error(`Vámi zadaná hodnota objednávky je vyšší, než povolené maximum (2 BTC).`);
                    }

                    if (data.btc.amount_decimal < minimalBitcoinPurchase) {
                        recalculateExchange(minimalBitcoinPurchase * btcRatio, "BTC", false);
                        const error = document.getElementById("buy-form-price-error");
                        if (error) {
                            error.innerHTML = `Vámi zadaná hodnota objednávky je nižší, než povolené minimum (${minimalBitcoinPurchase} BTC).`;
                            error.classList.remove('d-none');
                        }

                    }

                    if (type === 'BUY') {
                        document.getElementById("form-btc").value = data.btc.amount_decimal.toLocaleString('cs-CZ');
                        document.getElementById("form-czk").value = data.czk.amount_decimal.toLocaleString('cs-CZ');
                        document.getElementById("amount").value = data.czk.amount_decimal;
                    } else if (type === 'SELL') {
                        document.getElementById("sell-form-btc").value = data.btc.amount_decimal.toLocaleString('cs-CZ');
                        document.getElementById("sell-form-czk").value = data.czk.amount_decimal.toLocaleString('cs-CZ');
                        document.getElementById("sell-amount").value = data.btc.amount_decimal;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }


    // Buy
    const czkAmountInput = document.getElementById('form-czk');
    if (czkAmountInput) {
        czkAmountInput.addEventListener('change', function () {
            onChangeAmount(czkAmountInput, 'CZK');
        });

        czkAmountInput.addEventListener('paste', function () {
            onChangeAmount(czkAmountInput, 'CZK');
        });
    }

    // Buy
    const btcAmountInput = document.getElementById('form-btc');
    if (btcAmountInput) {
        btcAmountInput.addEventListener('change', function () {
            onChangeAmount(btcAmountInput, 'BTC');
        });

        btcAmountInput.addEventListener('paste', function () {
            onChangeAmount(btcAmountInput, 'BTC');
        });
    }

    // Sell
    const sellBtcAmountInput = document.getElementById('sell-form-btc');
    if (sellBtcAmountInput) {
        sellBtcAmountInput.addEventListener('change', function () {
            onChangeAmount(sellBtcAmountInput, 'BTC');
        });

        sellBtcAmountInput.addEventListener('paste', function () {
            onChangeAmount(sellBtcAmountInput, 'BTC');
        });
    }

    const sellCzkAmountInput = document.getElementById('sell-form-czk');
    if (sellCzkAmountInput) {
        sellCzkAmountInput.addEventListener('change', function () {
            onChangeAmount(sellCzkAmountInput, 'CZK');
        });

        sellCzkAmountInput.addEventListener('paste', function () {
            onChangeAmount(sellCzkAmountInput, 'CZK');
        });
    }



    // Predefined CZK values
    const formButtons = document.querySelectorAll(".btn-form-czk");
    formButtons.forEach(el => {
        el.addEventListener('click', () => {
            document.getElementById('form-czk').value = el.getAttribute('data-value');
            onChangeAmount(czkAmountInput, 'CZK');
        });
    });

    const deposit = document.querySelectorAll('[name="deposit"]');
    deposit.forEach(el => {
        el.addEventListener('change', function () {
            if (el.value === "1") {
                document.getElementById('show-on-deposit').classList.remove('d-none');
                document.getElementById('hide-on-deposit').classList.add('d-none');

                document.getElementById('form-phone').required = true;
                document.getElementById('form-btc-wallet').required = false;

            } else {
                document.getElementById('show-on-deposit').classList.add('d-none');
                document.getElementById('hide-on-deposit').classList.remove('d-none');

                document.getElementById('form-btc-wallet').required = true;
                document.getElementById('form-phone').required = false;
            }
        });
    });

    if (document.querySelector('[name="deposit"]:checked')) {
        if (document.querySelector('[name="deposit"]:checked').value === "1") {
            document.getElementById('show-on-deposit').classList.remove('d-none');
            document.getElementById('hide-on-deposit').classList.add('d-none');

            document.getElementById('form-phone').required = true;
            document.getElementById('form-btc-wallet').required = false;
        } else {
            document.getElementById('show-on-deposit').classList.add('d-none');
            document.getElementById('hide-on-deposit').classList.remove('d-none');

            document.getElementById('form-btc-wallet').required = true;
            document.getElementById('form-phone').required = false;
        }


    }
});