window.addEventListener('DOMContentLoaded', () => {
    const sendDepositSms = document.getElementById('sendDepositSms');
    if (sendDepositSms) {
        sendDepositSms.addEventListener('click', function (e) {
            e.preventDefault();
            const url = sendDepositSms.getAttribute('data-url');
            fetch(url, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                }
            })
                .then(response => {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }
                    return response;
                })
                .then(data => {
                    sendDepositSms.innerHTML = "Kód byl odeslán";
                    sendDepositSms.disabled = true;
                })
                .catch(function (err) {
                    console.log(err);
                    alert("Došlo k chybě, zkuste to prosím znovu později. Problém při odesílání SMS kódu.")
                });
        });
    }
}); 