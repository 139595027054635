import Chart from 'chart.js/auto';

document.addEventListener("DOMContentLoaded", function () {
    const btcData = async (range, limit = 1080, ytd, dimension = "HOUR") => {
        let response = null;
        let data = null;
        if (limit === 10080) {
            response = await fetch(`/get-data`);
            data = await response.json();
        } else {
            response = await fetch(`https://api.btcex.cz/ticker?limit=${limit}&period=${range}&dimension=${dimension}&keep_empty=false&public_key=5r95wf1`);
            data = await response.json();
        }

        if (ytd) {
            data = data.filter(item => item.created_at.indexOf(new Date().getFullYear()) !== -1);
        }

        const times = data.map(obj => new Date(obj.created_at));
        const prices = data.map(obj => obj.buy);

        return {
            times,
            prices
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    let width, height, gradient;
    function getGradient(ctx, chartArea) {
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;

        if (!gradient || width !== chartWidth || height !== chartHeight) {
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
            gradient.addColorStop(.5, 'rgba(16, 139, 228, .12)');
        }

        return gradient;
    }

    let createBtcChart;
    let btcChart = document.getElementById('btcChart');

    if (btcChart) {
        btcChart = btcChart.getContext('2d');
    }

    async function printBtcChart(days, limit, ytd) {
        let { times, prices } = await btcData(days, limit, ytd);

        prices = prices.filter(function (el) {
            if (el != 0) {
                return el;
            }
        });

        times = times.slice(-prices.length);

        Chart.defaults.font.family = "'Satoshi', 'sans-serif'";
        Chart.defaults.font.size = 12;

        createBtcChart = new Chart(btcChart, {
            type: 'line',
            maintainAspectRatio: false,
            data: {
                labels: times,
                datasets: [{
                    data: prices,
                    backgroundColor: function (context) {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        if (!chartArea) {
                            return;
                        }

                        return getGradient(ctx, chartArea);
                    },
                    borderColor: 'rgba(16, 139, 228, 1)',
                    fill: true,
                    borderJoinStyle: 'round',
                    borderCapStyle: 'round',
                    borderWidth: 3,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    lineTension: .2,
                    legend: false
                }]
            },

            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    x: {
                        display: true,
                        grid: {
                            display: false,
                        },
                        reverse: true,
                        ticks: {
                            maxTicksLimit: function (value) {

                                if (times.length === 168) {
                                    return 9;
                                }

                                if (times.length > 31) {
                                    return 12;
                                }

                                if (times.length === 24) {
                                    return 16;
                                }

                                console.log(times.length)

                                return 60;
                            },
                            maxRotation: 60,
                            minRotation: 60,
                            callback: function (value) {
                                if (times.length === 168) {
                                    return times[value].toLocaleDateString();
                                }


                                if (times.length > 31) {
                                    return capitalizeFirstLetter(times[value].toLocaleString('default', { month: 'long' })) + ' ' + times[value].getFullYear();
                                }

                                if (times.length === 24) {
                                    return times[value].toLocaleDateString([], { day: 'numeric', month: 'short' }) + ' ' + times[value].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                }

                                return times[value].toLocaleDateString();
                            }
                        },
                    },
                    y: {
                        display: true,
                        grid: {
                            display: true,
                            color: 'rgba(240, 245, 250, 1)'
                        },

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return new Intl.NumberFormat('cs-CZ', {
                                    style: 'currency',
                                    currency: 'CZK',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(value);
                            },
                        }
                    }
                },

                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        position: 'top',
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function (context) {
                                var label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('cs-CZ', {
                                        style: 'currency',
                                        currency: 'CZK',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    }).format(context.parsed.y);
                                }

                                return label;
                            },

                            title: function (tooltipItems) {
                                //Return value for titl
                                console.log()
                                let value = tooltipItems[0].dataIndex;

                                if (times.length > 31) {
                                    return times[value].toLocaleString('default', { year: 'numeric', month: '2-digit', day: '2-digit' });
                                }

                                if (times.length === 24) {
                                    return times[value].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                                }

                                return times[value].toLocaleDateString('default', { year: 'numeric', month: '2-digit', day: '2-digit' });

                            },

                            labelTextColor: function (context) {
                                return '#fff';
                            }
                        }
                    }
                },
            }
        });
    }

    async function getCurrentBitcoinPriceInCzk() {
        const url = `https://api.btcex.cz/price?type=BUY&amount=100000000&currency=BTC&public_key=5r95wf1`;
        let response = await fetch(url);
        let data = await response.json();
        return data.czk.amount_decimal;

    }

    async function updateBitcoinPrice() {
        let { times, prices } = await btcData(1, 1441, false, 'MINUTE');
        let currentPrice = await getCurrentBitcoinPriceInCzk();
        let yesterdayPrice = prices[1440].toFixed(0);

        const oneDayChange = ((prices[5].toFixed(0) - (yesterdayPrice)) / yesterdayPrice * 100);

        document.querySelectorAll(".btcPriceActual").forEach(el => {
            el.innerHTML = new Intl.NumberFormat('cs-CZ', {
                style: 'currency',
                currency: 'CZK',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(currentPrice)
        });

        if (oneDayChange > 0) {
            document.querySelectorAll(".btcPriceChange").forEach(el => {
                el.innerHTML = `+${oneDayChange.toFixed(2).replace('.', ',')}%`;
                el.classList.remove('text-danger');
                el.classList.add('text-success');
            });
        } else {
            document.querySelectorAll(".btcPriceChange").forEach(el => {
                el.innerHTML = `${oneDayChange.toFixed(2).replace('.', ',')}%`;
                el.classList.add('text-danger');
                el.classList.remove('text-success');
            });
        }
    }

    if (btcChart) {
        printBtcChart(1, 7 * 24, false);
    }


    if (document.querySelectorAll(".btcPriceActual").length > 0) {
        updateBitcoinPrice();
    }

    const rangeButtons = document.querySelectorAll('[name="range"]');

    rangeButtons.forEach(button => {
        button.addEventListener('click', async () => {
            let value = button.value;
            let limit = value;
            let ytd = false;
            let range = 24;

            if (value === '1d') {
                limit = 24;
                range = 1;
            }

            if (value === '7d') {
                limit = 24 * 7;
                range = 1;
            }

            if (value === '1m') {
                var now = new Date();
                const daysInThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
                limit = daysInThisMonth;
            }

            if (value === '6m') {
                limit = 182;
            }

            if (value === '1y') {
                limit = 365;
            }

            if (value === 'YTD') {
                ytd = true;
                limit = 365;
            }

            if (value === 'max') {
                limit = 10080;
            }

            createBtcChart.destroy();
            printBtcChart(range, limit, ytd);
        })
    });
});