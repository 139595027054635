window.addEventListener('DOMContentLoaded', () => {
    const btcWalletInput = document.getElementById('form-btc-wallet');
    const phoneInput = document.getElementById('form-phone');
    const czechAccountNumber = document.querySelector('.czech-account-number-validation');
    const continueButton = document.getElementById('continueButton');

    function validatePhone(phone) {
        const isValid = /^(\+420)[0-9]{3}[0-9]{3}[0-9]{3}$/.test(phone)

        if (isValid) {
            phoneInput.classList.add('is-valid');
            phoneInput.classList.remove('is-invalid');
        } else {
            phoneInput.classList.add('is-invalid');
            phoneInput.classList.remove('is-valid');
        }
    }

    async function validateBtcWallet(value) {
        const url = `https://api.btcex.cz/btc-address-validation/${value}`;

        await fetch(url)
            .then(data => {
                return data.json();
            })
            .then(data => {
                if (!data.valid) {
                    btcWalletInput.classList.add('is-invalid');
                    btcWalletInput.classList.remove('is-valid');

                    if (continueButton) {
                        continueButton.setAttribute('disabled', 'disabled');
                        continueButton.classList.add('disabled');
                    }
                } else {
                    btcWalletInput.classList.add('is-valid');
                    btcWalletInput.classList.remove('is-invalid');

                    if (continueButton) {
                        continueButton.removeAttribute('disabled');
                        continueButton.classList.remove('disabled');
                    }
                }
            })
            .catch(e => {
                alert(e);
            });
    }


    function validateCzechBankAccount(value) {
        const isValid = /^(([0-9]{0,6})?(-|–))?([0-9]{2,10})\/([0-9]{4})$/.test(value);

        if (isValid) {
            czechAccountNumber.classList.add('is-valid');
            czechAccountNumber.classList.remove('is-invalid');

            if (continueButton) {
                continueButton.removeAttribute('disabled');
                continueButton.classList.remove('disabled');
            }
        } else {
            czechAccountNumber.classList.add('is-invalid');
            czechAccountNumber.classList.remove('is-valid');

            if (continueButton) {
                continueButton.setAttribute('disabled', 'disabled');
                continueButton.classList.add('disabled');
            }
        }

    }



    if (btcWalletInput) {
        btcWalletInput.addEventListener('change', (e) => {
            validateBtcWallet(btcWalletInput.value);
        });
    }

    if (phoneInput) {
        phoneInput.addEventListener('change', (e) => {
            validatePhone(phoneInput.value);
        });
    }

    if (czechAccountNumber) {
        czechAccountNumber.addEventListener('change', (e) => {
            validateCzechBankAccount(czechAccountNumber.value);
        });
    }
});