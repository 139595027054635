document.addEventListener("DOMContentLoaded", function (event) {
    const type = document.querySelectorAll('[name="form_type"]');
    type.forEach(el => {
        el.addEventListener('change', function () {
            if (el.value == 'buy') {
                document.getElementById('buyForm').classList.remove('d-none');
                document.getElementById('sellForm').classList.add('d-none');
            } else if (el.value == 'sell') {
                document.getElementById('buyForm').classList.add('d-none');
                document.getElementById('sellForm').classList.remove('d-none');
            }
        });
    });
});

window.addEventListener('DOMContentLoaded', () => {
    const buyForm = document.getElementById('buyFormPost');
    if (buyForm) {
        buyForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(buyForm);
            const url = buyForm.getAttribute('action');
            document.getElementById('submitBuyFormButton').setAttribute('disabled', 'disabled');

            fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                }
            })
                .then(result => result.json())
                .then(data => {
                    const errorList = document.getElementById('buyFormErrorList');
                    errorList.classList.add('d-none');

                    if (data.errors) {
                        const errors = data.errors;

                        errorList.innerHTML = '';
                        for (const key in errors) {
                            if (Object.hasOwnProperty.call(errors, key)) {
                                const element = errors[key];
                                const span = document.createElement('span');
                                span.innerHTML = element;
                                errorList.appendChild(span);
                            }
                        }   
                        errorList.classList.remove('d-none');
                        document.getElementById('submitBuyFormButton').removeAttribute('disabled');
                        return;
                    }


                    const thanksModal = new bootstrap.Modal(document.getElementById('thanksModal'), {
                        keyboard: true
                    });
                    const expirationAt = data.rate.expiration_at;
                    const amountCzk = data.amounts.current.czk.amount_decimal;
                    const amountBtc = data.amounts.current.btc.amount_decimal;
                    const email = data.email;

                    document.getElementById('thankModalCzk').value = amountCzk;
                    document.getElementById('thankModalBtc').value = amountBtc;
                    document.getElementById('thanksModalEmail').innerHTML = email;


                    // Set the date we're counting down to
                    var countDownDate = new Date(expirationAt).getTime();

                    function countDown() {
                        var now = new Date().getTime();
                        var distance = countDownDate - now;
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        document.getElementById("countDown").innerHTML = minutes + ":" + seconds;

                        if (distance < 0) {
                            document.getElementById("countDown").innerHTML = "Kurz vypršel";
                        }
                    }

                    setInterval(countDown, 1000);

                    thanksModal.show();
                    buyForm.reset();
                    document.getElementById('submitBuyFormButton').removeAttribute('disabled');
                })
                .catch(function (err) {
                    console.log(err);
                    alert("Došlo k chybě, zkuste to prosím znovu později.")
                });
        });
    }


    /* SELL */
    const sellForm = document.getElementById('sellFormPost');
    if (sellForm) {
        sellForm.addEventListener('submit', function (e) {
            e.preventDefault();
            const formData = new FormData(sellForm);
            const url = sellForm.getAttribute('action');

            fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute("content")
                }
            })
                .then(result => result.json())
                .then(data => {
                    let thanksSellModal = new bootstrap.Modal(document.getElementById('thanksSellModal'), {
                        keyboard: true
                    });

                    const expirationAt = data.rate.expiration_at;
                    const amountCzk = data.amounts.current.czk.amount_decimal;
                    const amountBtc = data.amounts.current.btc.amount_decimal;
                    const email = data.email;

                    document.getElementById('thanksSellModalCzk').value = amountCzk;
                    document.getElementById('thanksSellModalBtc').value = amountBtc;
                    document.getElementById('thanksSellModalEmail').innerHTML = email;


                    // Set the date we're counting down to 
                    var countDownDate = new Date(expirationAt).getTime();

                    function countDown() {
                        var now = new Date().getTime();
                        var distance = countDownDate - now;
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        document.getElementById("countDownsellModal").innerHTML = minutes + ":" + seconds;

                        if (distance < 0) {
                            document.getElementById("countDownsellModal").innerHTML = "Kurz vypršel";
                        }
                    }

                    setInterval(countDown, 1000);

                    thanksSellModal.show();
                    sellForm.reset();
                })
                .catch(function (err) {
                    console.log(err);
                    alert("Došlo k chybě, zkuste to prosím znovu později.")
                });
        });
    }
});