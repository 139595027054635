import { BcCookie } from "./cookie";
import { BcModal } from './modal';

const bcModal = new BcModal();
const expirationInDays = 180;




export class BcCookieConsent {
    constructor() {
        this.container = "#bc-cookieconsent";
        this.init();
    }

    init() {
        window.addEventListener('DOMContentLoaded', () => {
            let bcCookieConsent = BcCookie.get('bc_cookieconsent');
            let prefreneces = BcCookie.get('bc_cookieconsent_settings');

            if (!bcCookieConsent || !prefreneces) {
                document.querySelector(this.container).style.display = "block";

                BcCookie.create('bc_cookieconsent_settings', '{"necessary":true, "marketing":false, "analytics": false}', expirationInDays);

                return;
            }

            prefreneces = JSON.parse(prefreneces);

            if (prefreneces.analytics) {
                this.allowAnalyticsCookies();
            }

            if (prefreneces.marketing) {
                this.allowMarketingCookies();
            }
        });
    }

    hide() {
        document.querySelector(this.container).style.display = "none";
        bcModal.close();
    }

    rejectAllCookies() {
        BcCookie.create('bc_cookieconsent', false, 0.5);
        BcCookie.create('bc_cookieconsent_settings', '{"necessary":true, "marketing":false, "analytics": false}', expirationInDays)
        bcModal.close();
    }

    allowAllCookies() {
        BcCookie.create('bc_cookieconsent', true, expirationInDays);
        BcCookie.create('bc_cookieconsent_settings', '{"necessary":true, "marketing":true, "analytics": true}', expirationInDays);

        this.allowAnalyticsCookies();

        bcModal.close();
    }

    allowAnalyticsCookies() {
        const analyticsCookiesScripts = document.querySelectorAll('[data-cookies-category="analytics"]');
        const marketingCookiesScripts = document.querySelectorAll('[data-cookies-category="marketing"]');

        if (typeof window.gtag !== 'undefined') {
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });
        }

        analyticsCookiesScripts.forEach(element => eval(
            element.innerHTML
        ));


    }

    allowMarketingCookies() {
        const analyticsCookiesScripts = document.querySelectorAll('[data-cookies-category="analytics"]');
        const marketingCookiesScripts = document.querySelectorAll('[data-cookies-category="marketing"]');

        marketingCookiesScripts.forEach(element => eval(
            element.innerHTML
        ));
    }
}

const bcCookieConsent = new BcCookieConsent();

// Allow selected cookies
window.addEventListener('DOMContentLoaded', () => {
    document.getElementById('bc-cookieconsent-allow-selected').onclick = function (e) {

        let form = document.getElementById('bc-cookieconsent-form');
        let prefreneces = JSON.parse(BcCookie.get('bc_cookieconsent_settings'));

        let analyticsCheckbox = document.getElementById("bc_cookieconsent_analytics");
        let marketingCheckbox = document.getElementById("bc_cookieconsent_marketing");

        prefreneces.analytics = analyticsCheckbox.checked;
        prefreneces.marketing = marketingCheckbox.checked;

        BcCookie.create('bc_cookieconsent_settings', JSON.stringify(prefreneces), expirationInDays);
        BcCookie.create('bc_cookieconsent', true, expirationInDays);

        if (analyticsCheckbox.checked) {
            bcCookieConsent.allowAnalyticsCookies();
        }

        if (marketingCheckbox.checked) {
            bcCookieConsent.allowMarketingCookies();
        }

        bcCookieConsent.hide();
    }


    // Allow all cookies
    Array.prototype.slice.call(document.querySelectorAll('.bc-cookieconsent-allow-all')).forEach(function (trigger) {
        trigger.addEventListener('click', function (event) {
            bcCookieConsent.allowAllCookies();
            bcCookieConsent.hide();
        });
    });

    // Reject all cookies
    Array.prototype.slice.call(document.querySelectorAll('.bc-cookieconsent-reject-all')).forEach(function (trigger) {
        trigger.addEventListener('click', function (event) {
            bcCookieConsent.rejectAllCookies();
            bcCookieConsent.hide();
        });
    });

    if (document.getElementById('bc-modal-close')) {
        document.getElementById('bc-modal-close').addEventListener('click', function () {
            bcModal.close();
        })
    }

    // Show settings modal
    if (document.getElementById('bc-cookieconsent-show-settings')) {
        document.getElementById('bc-cookieconsent-show-settings').onclick = function (e) {
            bcModal.show();
        }
    }

    document.onkeydown = function (e) {
        if (e.key === "Escape") {
            bcModal.close();
        }
    };
});