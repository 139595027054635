function validateEmail(email) {
    const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return re.test(String(email).toLowerCase());
}

export function setError(el, message) {
    el.innerHTML = message;

    if (message.length > 0) {
        el.classList.remove("d-none");
    } else {
        el.classList.add('d-none');
    }
}

const emailInput = document.getElementById('form-email');

if (emailInput) {
    emailInput.addEventListener('change', (e) => {
        if (!validateEmail(emailInput.value)) {
            setError(document.getElementById('form-email-error'), 'Zadejte platnou e-mailovou adresu.');
        } else {
            setError(document.getElementById('form-email-error'), '');
        }
    });
}