export class BcModal {

    constructor(animationDuration = 400) {
        this.animationDuration = animationDuration;
    }

    toggleBackdrop() {
        var backdrop = document.getElementById("bc-modal-backdrop");

        if(!backdrop) {
            backdrop = document.createElement("div");
            backdrop.id = "bc-modal-backdrop";
            backdrop.classList.add("bc-modal-backdrop", 'fade');

            document.body.appendChild(backdrop);
            backdrop.classList.add("show");

            return;
        }

        backdrop.classList.remove('show');

        setTimeout(function () {
            document.getElementById("backdrop").remove();
        }, this.animationDuration);

        return;
    }

    show() {
        const modal = document.querySelector(".bc-modal");

        if(!modal) {
            return;
        }

        modal.classList.add('show');
        document.body.classList.add('bc-modal-open');
        this.toggleBackdrop();
    }

    close() {
        const modal = document.querySelector(".bc-modal.show");

        if(!modal) {
            return;
        }

        modal.classList.remove('show');
        document.body.classList.remove('bc-modal-open');
        this.toggleBackdrop();

    }
}

